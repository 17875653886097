import { Card, CardBody, CardTitle, Progress } from "reactstrap"

export default function ProgressCard({ heading, count, percentage }) {
  return (
    <Card style={{ width: "100%" }}>
      <CardBody>
        <CardTitle className="h4">{heading}</CardTitle>
        <p className="card-title-desc">{count}</p>

        <div>
          <div className="mb-4">
            <Progress color="info" value={percentage} />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}
