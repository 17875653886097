import { Sparklines, SparklinesBars } from "react-sparklines"
import { Card, CardBody } from "reactstrap"
//import { FaStar } from "../../../node_modules/react-icons/fa"
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa"

export default function StarRatings({ ratingData }) {
  //var mapdata = ratingData.map((value, index) => {})
  const istotalratingAvailable = ratingData?.total_rating || false
  var overallRating = 0.0
  if (istotalratingAvailable) {
    overallRating = (
      (ratingData.rating_index["1"] +
        ratingData.rating_index["2"] * 2 +
        ratingData.rating_index["3"] * 3 +
        ratingData.rating_index["4"] * 4 +
        ratingData.rating_index["5"] * 5) /
      ratingData.total_rating
    ).toFixed(1)
  }
  const fullstars = Math.floor(overallRating)

  const fractionalPart = overallRating - fullstars
  const fullStars = Array(fullstars).fill(
    <FaStar className="align-middle" color="#f1b44c" />
  )
  const emptyStarsCount = 5 - fullstars - (fractionalPart >= 0.5 ? 1 : 0)
  return (
    <Card style={{ border: "none" }}>
      <CardBody>
        <div className="d-flex justify-content-between gap-2">
          {/* <p className="text-right">{overallRating}</p> */}

          <div className="align-middle">
            {/* Render full stars */}
            {fullStars.map((star, index) => (
              <span key={index}>{star}</span>
            ))}

            {/* Conditionally render a half star if fractional part is >= 0.5 */}
            {fractionalPart >= 0.5 && (
              <FaStarHalfAlt className="align-middle" color="#f1b44c" />
            )}

            {/* Render empty stars */}
            {Array(emptyStarsCount)
              .fill(<FaRegStar className="align-middle" color="#f1b44c" />)
              .map((star, index) => (
                <span key={fullstars + index}>{star}</span>
              ))}
          </div>
        </div>
      </CardBody>
    </Card>
  )
}
