import React, { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { FaCirclePlus } from "react-icons/fa6"
import AppListModal from "../../additional_components/common/Modals/AppListModal"
import { Link } from "react-router-dom"
import classnames from "classnames"
import ComparisonTabs from "../AppTracking/comparisonTabs"
import { api } from "../../utils/variables"
import axios from "axios"
import FeatureTabs from "./featureTabs"
import AppSearchModal from "../../additional_components/common/Modals/AppSearchModal"
import metalogo from "../../../src/assets/images/meta-logo.png"
import sidelogo from "../../../src/assets/images/SideQuest-Mark-White.png"
import KeywordDataTable from "../../additional_components/marketResearch/keywordTracking/KeywordDataTable"

export default function KeywordTracking() {
  // const [activeTab1, setactiveTab1] = useState("5")

  const [inputValue, setInputValue] = useState("")
  const [modal_toggle, setmodal_toggle] = useState(false)

  const [trackedKeyword, setTrackedKeyword] = useState([])
  const [selectedApp, setSelectedApp] = useState(null)
  const [loading, setLoading] = useState(false)
  const [predefKeyword, setpredef] = useState([])

  const storedgameAppData = sessionStorage.getItem("selectedAppKeyword")
  // useEffect(() => {
  //   fetchpredefinedwords()
  //   if (storedgameAppData) {
  //     setgamesresponse(JSON.parse(storedgamesAppsData))
  //     setFilteredGames(JSON.parse(storedgamesAppsData))
  //   }

  const fetchpredefinedwords = () => {
    axios
      .get(`${api}/tracker/predefined/keyword`, {})
      .then(res => {
        //console.log(res.data.keywords)
        setpredef(res.data.keywords)
      })
      .catch(error => {
        console.error("Error fetching data:", error)
      })
  }

  useEffect(() => {
    if (storedgameAppData) {
      setSelectedApp(JSON.parse(storedgameAppData))
    }
    fetchpredefinedwords()

    // console.log(`List on load: ${comparisonBetween}`)
  }, [])

  function toggle_modal() {
    setmodal_toggle(!modal_toggle)
  }
  const handleselectedapp = async app => {
    // setLoading(true)

    axios.get(`${api}/tracker/app/${app.item_id}`, {}).then(res => {
      setSelectedApp(res.data.details)

      //adding session element
      sessionStorage.setItem(
        "selectedAppKeyword",
        JSON.stringify(res.data.details)
      )
      console.log(res.data.details)
    })

    toggle_modal()
  }

  // add keyword b y pressing enter
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      //handleSearch() // Call the function when Enter is pressed
      if (trackedKeyword.length < 5) {
        if (!trackedKeyword.includes(inputValue.trim())) {
          setTrackedKeyword(prevItems => [...prevItems, inputValue.trim()]) // Add inputValue to items
        }
        setInputValue("") // Clear the input field
      } else {
        alert("Only 5 keywords can be tracked")
      }
    }
  }

  //add keyword by clicking add button
  const handleAddButton = async () => {
    if (trackedKeyword.length < 5) {
      if (!trackedKeyword.includes(inputValue.trim())) {
        setTrackedKeyword(prevItems => [...prevItems, inputValue.trim()]) // Add inputValue to items
      }
      setInputValue("")
    } else {
      alert("Only 5 keywords can be tracked")
    }
  }

  //removing word from keyword array
  const removeItem = indexToRemove => {
    setTrackedKeyword(prevItems =>
      prevItems.filter((_, index) => index !== indexToRemove)
    )
  }

  return (
    <React.Fragment>
      <div className="page-content m-3">
        <Breadcrumb
          title="Keyword Tracking"
          breadcrumbItem="Keyword Tracking"
        />
        <Row className="m-3">
          <Card className="">
            <CardBody>
              <Row className="mt-3">
                <div className="d-flex justify-content-between">
                  {/* <p>Select an App</p> */}
                </div>
              </Row>
              <Row>
                <div className="d-flex flex-wrap gap-2 justify-content-center">
                  {selectedApp == null ? (
                    <div className="col-md-2">
                      <Card
                        className="app-card-container2  border border-1 rounded"
                        onClick={toggle_modal} // Replace with your click handler
                        style={{
                          border: "none",
                          cursor: "pointer", // Makes the card look clickable
                          transition: "background-color 0.3s ease", // Smooth transition for hover effect
                          minHeight: 80,
                        }}
                      >
                        <CardBody className=" mx-auto">
                          <div
                            className="d-flex gap-2 my-auto mb-2"
                            style={{ minHeight: 180 }}
                          >
                            <FaCirclePlus size={20} className="my-auto" />
                            <p className="my-auto">
                              <b>Select game</b>
                            </p>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  ) : (
                    <Card className="w-100">
                      <Row className="g-0 align-items-center">
                        <Col md={5}>
                          <CardImg
                            className="img-fluid p-2"
                            src={
                              selectedApp?.image_url ||
                              "https://via.placeholder.com/50"
                            }
                            alt="image cap"
                            style={{
                              minHeight: 200,
                              maxHeight: 200,
                              minWidth: 250,
                              objectfit: "cover",
                            }}
                          />
                        </Col>
                        <Col md={7}>
                          <CardBody>
                            {/* <CardTitle className="display-1">

                          </CardTitle> */}
                            <b className="display-6 text-white">
                              {selectedApp?.name || "Demo1"}
                            </b>

                            <CardText>
                              {selectedApp?.category || "category"}
                            </CardText>
                            <CardText>
                              <div className="d-flex justify-content-start gap-4">
                                <a href={selectedApp?.website_url}>
                                  {selectedApp?.publisher || "publisher"}
                                </a>
                                <Link to="/publisherapps">
                                  <p>
                                    All games from:{" "}
                                    {selectedApp?.publisher || "publisher"}
                                  </p>
                                </Link>
                              </div>
                            </CardText>

                            <div className="d-flex justify-content-start gap-1">
                              {selectedApp?.supported_platforms != null ? (
                                <img
                                  src={metalogo}
                                  className="rounded avatar-sm align-self-center"
                                  alt=""
                                  style={{
                                    minHeight: 30,
                                    maxHeight: 30,
                                    minWidth: 40,
                                    maxWidth: 40,
                                  }}
                                />
                              ) : (
                                <img
                                  src={sidelogo}
                                  className="rounded avatar-sm align-self-center"
                                  alt=""
                                  style={{
                                    minHeight: 20,
                                    maxHeight: 20,
                                    minWidth: 20,
                                    maxWidth: 20,
                                  }}
                                />
                              )}

                              <small className="text-muted align-self-center">
                                Last updated 3 mins ago
                              </small>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  )}
                </div>
              </Row>

              {selectedApp != null ? (
                <Row style={{ paddingBottom: 20 }}>
                  <div className="d-flex justify-content-center">
                    <Button
                      className="col-md-3"
                      color={"primary"}
                      onClick={toggle_modal}
                      style={{
                        cursor: "pointer", // Change cursor based on selection
                        // Different color for already selected apps
                      }}
                    >
                      Select Another App
                    </Button>
                  </div>
                </Row>
              ) : (
                <></>
              )}
            </CardBody>
          </Card>
        </Row>

        <Row className="m-3">
          <Card>
            <CardBody>
              <CardTitle>Keyword Section</CardTitle>

              <div className="d-flex flex-wrap gap-2 mt-3">
                {predefKeyword.map((value, index) => {
                  return (
                    <Button
                      key={index}
                      color="dark"
                      className="btnparent btn-rounded"
                      onClick={() => {
                        console.log(`selected word:${value}`)
                        if (trackedKeyword.length < 5) {
                          if (!trackedKeyword.includes(value.trim())) {
                            setTrackedKeyword(prevItems => [
                              ...prevItems,
                              value.trim(),
                            ])
                          }
                        } else {
                          alert("only 5 keywords can be added")
                        }
                      }}
                    >
                      <div className="keywordbtn">{value}</div>
                    </Button>
                  )
                })}
              </div>
              <Row className="mb-4 mt-4">
                <Label
                  htmlFor="horizontal-firstname-input"
                  className="col-sm-2 col-form-label"
                >
                  Enter Keyword
                </Label>
                <Col sm={6}>
                  <Input
                    type="text"
                    className="form-control"
                    id="horizontal-firstname-input"
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Col>
                <Col sm={2}>
                  <Button color="primary" onClick={handleAddButton}>
                    ADD
                  </Button>
                </Col>
              </Row>

              <Row>
                <div className="d-flex flex-wrap justify-content-start align-items-center gap-2">
                  <b className="col-sm-2">Tracked Words:</b>
                  {trackedKeyword.map((value, index) => {
                    return (
                      <div id="liveAlertPlaceholder">
                        <div>
                          <Alert
                            color="primary"
                            isOpen={true}
                            toggle={() => removeItem(index)}
                          >
                            {value}
                          </Alert>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </Row>
            </CardBody>
          </Card>
        </Row>
        {/* Comparison tables and charts */}
        <Row className="m-3">
          {/* <ComparisonTabs appListData={comparisonBetween}></ComparisonTabs> */}
          {/* <FeatureTabs appListData={comparisonBetween}></FeatureTabs> */}
          <KeywordDataTable keywordData={trackedKeyword}></KeywordDataTable>
        </Row>
      </div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <Spinner size="lg" color="primary" />
        </div>
      )}
      <AppSearchModal
        modaltoggle={modal_toggle}
        togglemodal={toggle_modal}
        onAppSelect={handleselectedapp}
      ></AppSearchModal>
    </React.Fragment>
  )
}
