import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardImg,
  CardTitle,
  Button,
} from "reactstrap"
import Slider from "react-slick"

const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 3, // Adjust as needed
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}
export default function ScreenShotCarousel({ appData }) {
  //   const [allimages, setallimages] = useState([])
  useEffect(() => {
    //setallimages(imageurls?.screenshots)
    //console.log(appData)
  })

  return (
    <>
      {appData.screenshots ? (
        <Slider {...carouselSettings}>
          {appData.screenshots && appData.screenshots.length > 0 ? (
            appData.screenshots.map((screenshot, index) => (
              <div
                key={index}
                style={{
                  padding: "0 15px", // Add padding between items
                }}
              >
                <Card
                  style={{
                    maxWidth: "400px",
                    minHeight: "150px",
                    maxHeight: "250px",
                  }}
                >
                  <CardImg
                    top
                    width="100%"
                    src={screenshot}
                    alt={`Game Image ${index + 1}`}
                    style={{
                      minHeight: 250,
                      maxHeight: 250,
                      objectFit: "cover", // Ensures the image fits well within the card
                      borderRadius: "10px", // Optional rounded corners for images
                    }}
                  />
                </Card>
              </div>
            ))
          ) : (
            <Card>
              <CardBody>
                <p>No screenshots available</p>
              </CardBody>
            </Card>
          )}
        </Slider>
      ) : (
        <Card>
          <CardBody>
            <p>No screenshots available</p>
          </CardBody>
        </Card>
      )}
    </>
  )
}
