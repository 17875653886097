import { Sparklines, SparklinesBars } from "react-sparklines"
import { Card, CardBody } from "reactstrap"
//import { FaStar } from "../../../node_modules/react-icons/fa"
import { FaStar } from "react-icons/fa"

export default function BarChart({ ratingData }) {
  //var mapdata = ratingData.map((value, index) => {})
  const istotalratingAvailable = ratingData?.total_rating || false
  var overallRating = 0.0
  if (istotalratingAvailable) {
    overallRating = (
      (ratingData.rating_index["1"] +
        ratingData.rating_index["2"] * 2 +
        ratingData.rating_index["3"] * 3 +
        ratingData.rating_index["4"] * 4 +
        ratingData.rating_index["5"] * 5) /
      ratingData.total_rating
    ).toFixed(1)
  }

  return (
    <Card style={{ border: "none" }}>
      <CardBody>
        <div className="d-flex flex-wrap justify-content-between mb-2">
          <p className="text-right">{ratingData?.total_rating || "N/A"}</p>
          <div className="d-flex justify-content-between gap-1">
            <div className="align-middle">
              <FaStar className="align-middle" color="#f1b44c" />
            </div>
            <div className="align-middle">
              <p className="text-right align-middle">{overallRating}</p>
            </div>
          </div>
        </div>
        <div className="text-center">
          <Sparklines
            height={100}
            data={
              istotalratingAvailable
                ? [
                    ratingData.rating_index?.["1"] || 0,
                    ratingData.rating_index?.["2"] || 0,
                    ratingData.rating_index?.["3"] || 0,
                    ratingData.rating_index?.["4"] || 0,
                    ratingData.rating_index?.["5"] || 0,
                  ]
                : []
            }
          >
            <SparklinesBars
              style={{ fill: "#f1b44c" }}
              barWidth={50}
              //   barSpacing={1}
            />
          </Sparklines>
        </div>
      </CardBody>
    </Card>
  )
}
