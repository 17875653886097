import axios from "axios"
import { useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  Col,
  Input,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap"
import { api } from "../../../utils/variables"

export default function AppSearchModal({
  modaltoggle,
  togglemodal,
  onAppSelect,
}) {
  const [inputValue, setInputValue] = useState("")
  const [searchValue, setsearchValue] = useState("")
  const [categoryApps, setcategoryApps] = useState([])
  const [filteredApps, setCatFilteredApps] = useState([])
  const [dataAction, setDataAction] = useState("loading")
  const [loading, setLoading] = useState(false)

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      // setLoading(true)
      handleSearch() // Call the function when Enter is pressed
    }
  }

  const fetchSearchResult = async value => {
    try {
      // Initial API call to get the action and details
      let res = await axios.get(`${api}/tracker/keyword/${value}`, {})
      setDataAction(res.data.action)
      //toggle_modal()
      // Polling until the action becomes "display"
      while (res.data.action.toLowerCase() !== "display") {
        console.log("Waiting for action to become 'display'...")

        // Wait for 2 seconds before fetching again
        await new Promise(resolve => setTimeout(resolve, 2000))

        // Fetch again to check the action
        res = await axios.get(`${api}/tracker/keyword/${value}`, {})
        setDataAction(res.data.action)
      }

      // Once action is "display", fetch the games
      console.log("Action is 'display', fetching games...")
      setcategoryApps(res.data.games)
      setCatFilteredApps(res.data.games)
      sessionStorage.setItem("categoryApps", JSON.stringify(res.data.games))
      sessionStorage.setItem("filteredApps", JSON.stringify(res.data.games))

      // Close modal since we are done
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }
  const handleSearch = async () => {
    setLoading(true)
    await fetchSearchResult(inputValue)
    await setsearchValue(inputValue)
    setLoading(false)
  }
  return (
    <Modal
      isOpen={modaltoggle}
      toggle={() => {
        togglemodal()
      }}
      centered
      size="lg"
      style={{ maxHeight: "100vh" }}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Select an App
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            togglemodal()
          }}
          aria-label="Close"
        ></button>
      </div>
      <ModalBody
        style={{
          maxHeight: "80vh",
          minHeight: "40vh",
          padding: "20px",
          overflowY: "auto",
        }}
      >
        <div>
          <Col>
            <div
              className="d-flex flex-wrap gap-3"
              style={{ position: "sticky", top: 0, zIndex: 10 }}
            >
              <div className="search-box chat-search-box position-relative col-sm-auto">
                <Input
                  type="text"
                  className="form-control bg-light border-light rounded"
                  placeholder="Search..."
                  value={inputValue}
                  onChange={e => setInputValue(e.target.value)} // Update input value
                  onKeyDown={handleKeyDown}
                />
                <i className="uil uil-search search-icon"></i>
              </div>
              <Button
                color="primary"
                className=" col-sm-auto waves-effect waves-light"
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
          </Col>
          {/* return search list */}

          <Col className="mt-4">
            <hr></hr>
            <p>Search result</p>
            {filteredApps.map((val, index) => {
              return (
                <>
                  <Row className="pb-2">
                    <Col lg={12}>
                      <Card className="mb-1" key={index}>
                        <Row className="g-0 align-items-center">
                          <Col md={4}>
                            <CardImg
                              className="img-fluid p-2"
                              src={
                                val?.image_url ||
                                "https://via.placeholder.com/50"
                              }
                              alt="image cap"
                              style={{
                                minHeight: 70,
                                maxHeight: 70,
                                minWidth: 120,
                                maxWidth: 120,
                                objectfit: "cover",
                              }}
                            />
                          </Col>
                          <Col md={8}>
                            <CardBody>
                              <div className="d-flex justify-content-between">
                                <b className=" text-white">
                                  {val?.name || "Demo1"}
                                </b>
                                <Button
                                  color="primary"
                                  className="btn-rounded waves-effect waves-light"
                                  style={{ minWidth: 100 }}
                                  onClick={() => onAppSelect(val)}
                                >
                                  Select
                                </Button>
                              </div>
                            </CardBody>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </>
              )
            })}
          </Col>
        </div>
        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
            }}
          >
            <Spinner size="lg" color="primary" />
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}
