import axios from "axios"
import { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
} from "reactstrap"
import { api, token } from "../../../utils/variables"
import { FaLock } from "react-icons/fa"

export default function TrackingDetailsPopup({
  modaltoggle,
  togglemodal,
  metric,
  value,
}) {
  const [inputValue, setInputValue] = useState("")
  const [visible, setVisible] = useState(false)
  const [categoryApps, setcategoryApps] = useState([])
  const [filteredApps, setCatFilteredApps] = useState([])
  const [dataAction, setDataAction] = useState("loading")
  const [loading, setLoading] = useState(false)
  const analyticsDetails = {
    asr: {
      sign: "ASR",
      name: "Store Ranking",
      description:
        "Represents the app's rank in the store based on downloads and ratings.",
    },
    ktr: {
      sign: "KTR",
      name: "Key Transaction Rate",
      description:
        "Keyword Relevance measures how closely the app’s title, description, and metadata match the keywords users are searching for.",
    },
    ku: {
      sign: "KU",
      name: "Keyword Usage",
      description:
        "Keyword Usage measures how well an app utilizes high-ranking or relevant keywords in its metadata (title, description, and tags)",
    },
    aur: {
      sign: "AUR",
      name: "Average user rating",
      description:
        "High ratings indicate positive user sentiment, which can push an app higher in rankings. App stores typically prioritize apps with 4-star ratings or higher.",
    },
  }

  const selectedDetails = analyticsDetails[metric]
  const limitValue = Math.floor(value * 100) / 100
  useEffect(() => {
    console.log(metric)
  }, [])

  return (
    <Modal
      isOpen={modaltoggle}
      toggle={() => {
        togglemodal()
      }}
      centered
      size="md"
      style={{ maxHeight: "100vh" }}
    >
      <div className="modal-header bg-gradient-primary">
        <div className="d-flex justify-content-start gap-2 align-items-center">
          {/* <FaLock></FaLock> */}
          <h5 className="modal-title" id="staticBackdropLabel">
            {selectedDetails?.sign}
          </h5>
        </div>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            togglemodal()
          }}
          aria-label="Close"
        ></button>
      </div>
      <ModalBody
        className=""
        style={{
          maxHeight: "80vh",
          // minHeight: "40vh",
          padding: "20px",
          overflowY: "auto",
        }}
      >
        <div>
          {/* return search list */}
          <Row classname="d-flex align-items-stretch">
            <Col className="">
              <Card className="d-flex justify-content-center align-items-center text-center h-100">
                <CardBody>
                  <div className="">
                    <p className=" text-white">{selectedDetails?.name}</p>
                    <p className="text-white">{selectedDetails?.description}</p>
                    <h5 className="info">{limitValue}</h5>
                  </div>
                </CardBody>
                <CardFooter className="w-100">
                  <Button color="primary"> Details</Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  )
}
