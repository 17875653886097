import { Card, CardImg, CardBody, CardTitle, CardText } from "reactstrap"

const ArticleCard = ({ article }) => {
  return (
    <Card
      style={{
        margin: "20px",
        maxWidth: "100%",
        minHeight: "250px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Article Image */}
      <CardImg
        top
        width="100%"
        src={article.imageUrl}
        alt="Article Image"
        style={{ maxHeight: "400px", objectFit: "cover", padding: 10 }}
      />

      {/* Publishing Date */}
      <CardBody
        style={{
          flexDirection: "column",
          paddingTop: "10px",
          paddingBottom: "0",
        }}
      >
        <small
          className="text-muted"
          style={{ marginBottom: "10px", display: "block" }}
        >
          {article.publishDate}
        </small>

        {/* Article Title */}
        <CardTitle tag="h5">{article.title}</CardTitle>

        {/* Article Content */}
        <CardText style={{ marginTop: "10px" }}>
          {article.content.map((paragraph, index) => (
            <p key={index} style={{ marginBottom: "10px" }}>
              {paragraph}
            </p>
          ))}
        </CardText>
      </CardBody>
    </Card>
  )
}
export default ArticleCard
