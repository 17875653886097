import React from "react"
import { MDBDataTable } from "mdbreact"
import LineChart from "../common/charts-graphs/RankDashedLine"
import BarChart from "../common/charts-graphs/BarChart"
import { Button, Input } from "reactstrap"
import AppCard from "./AppCard"
// import "mdbreact/dist/css/mdb.css"
// import "bootstrap/dist/css/bootstrap.min.css"
import "../../assets/scss/datatables.scss"
import SmallAppCard from "./categoryAnalysis/SmallAppCard"
import StarRatings from "./categoryAnalysis/StartRatings"
const CategoryDataTable = ({ inputdata, gamesData }) => {
  const data = {
    columns: [
      {
        label: "Sno",
        field: "sno",
        sort: "asc",
      },
      {
        label: "App",
        field: "app",
        sort: "asc",
      },
      {
        label: "Category",
        field: "category",
        sort: "asc",
      },
      {
        label: "Ratings",
        field: "ratings",
        sort: "asc",
      },
      {
        label: "Estimated Downloads",
        field: "downloads",
        sort: "asc",
      },

      {
        label: "Released",
        field: "released",
        sort: "asc",
      },

      // Add more columns here
    ],
    rows: gamesData?.map((game, index) => ({
      sno: index + 1,
      app: <SmallAppCard key={game._id} gamedata={game} />,
      appNameSearch: game.name, //hidden field holds appname
      category: game?.category || inputdata,
      downloads: game?.downloads || "N/A",

      ratings: <StarRatings ratingData={game.rating} />,
      released: game.created_at,
    })),
  }

  return <MDBDataTable responsive bordered data={data} />
}

export default CategoryDataTable
