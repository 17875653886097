import React from "react"
import ReactEcharts from "echarts-for-react"
import { Card, CardBody } from "reactstrap"

const Line = ({ game }) => {
  const options = {
    tooltip: {
      trigger: "axis",
    },
    grid: {
      zlevel: 0,
      x: 50,
      x2: 50,
      y: 30,
      y2: 30,
      borderWidth: 0,
    },
    xAxis: {
      type: "category",
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      axisLabel: {
        color: "#ffffff",
      },
      axisLine: {
        lineStyle: {
          color: "#74788d",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        color: "#ffffff",
      },
      axisLine: {
        lineStyle: {
          color: "#74788d",
        },
      },
    },
    series: [
      {
        data: [620, 832, 750, 934, 1290, 1330, 1400],
        type: "line",
      },
    ],
    color: ["#556ee6"],
    textStyle: {
      color: ["#74788d"],
    },
  }
  return (
    <React.Fragment>
      <Card style={{ marginTop: 50 }}>
        {/* <CardImg
          width={50}
          src={game.imageUrl}
          alt="Game Image"
          style={{
            minHeight: 80,
            maxHeight: 80,
            maxWidth: 80,
            marginTop: 10,
          }}
        /> */}
        <CardBody></CardBody>
      </Card>
      <ReactEcharts style={{ height: "350px" }} option={options} />
    </React.Fragment>
  )
}
export default Line
