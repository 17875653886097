import { useState, useEffect } from "react"
import { Button, Container, Progress } from "reactstrap"

export default function StepperProgress({ closeModal, isFetched }) {
  const [progress, setProgress] = useState(0) // Initial progress is 0
  const [step, setStep] = useState(1) // Track which step is active
  const [btnColor, setbtnColor] = useState("secondary")
  useEffect(() => {
    const timeouts = []

    // Step 1: Progress to 50
    timeouts.push(
      setTimeout(() => {
        setProgress(50)
        setStep(2) // Move to step 2
      }, 1000)
    )

    // Step 3: Complete progress to 100
    console.log(isFetched)
    if (isFetched.toLowerCase() === "display") {
      timeouts.push(
        setTimeout(() => {
          setProgress(100)
          setStep(3) // Final step
        }, 2000)
      )

      // Close modal after stepper completes
      timeouts.push(
        setTimeout(() => {
          closeModal() // Close the modal
        }, 3000)
      )
    }

    // Cleanup timeouts if the component is unmounted
    return () => timeouts.forEach(timeout => clearTimeout(timeout))
  }, [closeModal])

  return (
    <Container>
      <div className="position-relative m-4">
        <Progress multi style={{ height: "1px" }}>
          <Progress color="primary" bar value={progress} />
        </Progress>
        <Button
          color="primary"
          size="sm"
          type="button"
          className="position-absolute top-0 start-0 translate-middle rounded-pill"
          style={{ width: "2rem", height: "2rem" }}
        >
          1
        </Button>
        <div
          className="position-absolute start-0 translate-middle text-center"
          style={{ top: "2.5rem", width: "4rem" }} // Position below the button
        >
          Grabbing your data
        </div>
        {step >= 2 ? (
          <Button
            color="primary"
            size="sm"
            type="button"
            className="position-absolute top-0 start-50 translate-middle rounded-pill"
            style={{ width: "2rem", height: "2rem" }}
          >
            2
          </Button>
        ) : (
          <Button
            color="secondary"
            size="sm"
            type="button"
            className="position-absolute top-0 start-50 translate-middle rounded-pill"
            style={{ width: "2rem", height: "2rem" }}
          >
            2
          </Button>
        )}

        <div
          className="position-absolute start-50 translate-middle text-center"
          style={{ top: "2.5rem", width: "4rem" }} // Position below the button
        >
          Organising all data
        </div>
        {step >= 3 ? (
          <Button
            color="primary"
            size="sm"
            type="button"
            className="position-absolute top-0 start-100 translate-middle rounded-pill"
            style={{ width: "2rem", height: "2rem" }}
          >
            3
          </Button>
        ) : (
          <Button
            color="secondary"
            size="sm"
            type="button"
            className="position-absolute top-0 start-100 translate-middle rounded-pill"
            style={{ width: "2rem", height: "2rem" }}
          >
            3
          </Button>
        )}
        <div
          className="position-absolute start-100 translate-middle text-center"
          style={{ top: "2.5rem", width: "4rem" }} // Position below the button
        >
          Almost ready
        </div>
      </div>
    </Container>
  )
}
