import axios from "axios"
import { useState } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Input,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap"
import { api, token } from "../../../utils/variables"
import { FaLock } from "react-icons/fa"

export default function SubscriptionPopUpModal({ modaltoggle, togglemodal }) {
  const [inputValue, setInputValue] = useState("")
  const [visible, setVisible] = useState(false)
  const [categoryApps, setcategoryApps] = useState([])
  const [filteredApps, setCatFilteredApps] = useState([])
  const [dataAction, setDataAction] = useState("loading")
  const [loading, setLoading] = useState(false)

  return (
    <Modal
      isOpen={modaltoggle}
      toggle={() => {
        togglemodal()
      }}
      centered
      size="lg"
      style={{ maxHeight: "100vh" }}
    >
      <div className="modal-header">
        <div className="d-flex justify-content-start gap-2 align-items-center">
          <FaLock></FaLock>
          <h5 className="modal-title" id="staticBackdropLabel">
            Upgrade your plan to unlock this feature
          </h5>
        </div>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            togglemodal()
          }}
          aria-label="Close"
        ></button>
      </div>
      <ModalBody
        style={{
          maxHeight: "80vh",
          // minHeight: "40vh",
          padding: "20px",
          overflowY: "auto",
        }}
      >
        <div>
          {/* return search list */}
          <Row classname="d-flex align-items-stretch">
            <div id="liveAlertPlaceholder">
              <div>
                <Alert
                  color="info"
                  isOpen={visible}
                  toggle={() => setVisible(false)}
                >
                  Buying option is not available for now!
                </Alert>
              </div>
            </div>
            <Col className="mt-4">
              <Card className="d-flex justify-content-center align-items-center text-center h-100">
                <CardBody>
                  <div className="">
                    <p className="text-white">Standard</p>
                    <ul>
                      <li>A detailed breakdown of feature impacts</li>
                      <li>Implementation examples of key features</li>
                      <li>Competitors with similar feature-sets</li>
                    </ul>
                  </div>
                </CardBody>
                <CardFooter className="w-100">
                  <Button
                    className="w-100"
                    color="primary"
                    onClick={() => setVisible(true)}
                  >
                    BUY @ 9.99$
                  </Button>
                </CardFooter>
              </Card>
            </Col>
            <Col className="mt-4" l>
              <Card className="d-flex justify-content-center align-items-center text-center h-100">
                <CardBody>
                  <div className="">
                    <p className="text-white">Premium</p>
                    <ul>
                      <li>A detailed breakdown of feature impacts</li>
                      <li>Implementation examples of key features</li>
                      <li>Competitors with similar feature-sets</li>
                      <li>
                        Compare the game with your concepts and other unlocked
                        games for a full feature comparison
                      </li>
                    </ul>
                  </div>
                </CardBody>
                <CardFooter className="w-100">
                  <Button
                    className="w-100"
                    color="primary"
                    onClick={() => setVisible(true)}
                  >
                    BUY @ 19.99$
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  )
}
