import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  Col,
  Input,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { FaCirclePlus } from "react-icons/fa6"
import AppListModal from "../../additional_components/common/Modals/AppListModal"
import { Link } from "react-router-dom"
import classnames from "classnames"
import ComparisonTabs from "./comparisonTabs"
import { api, token } from "../../utils/variables"
import axios from "axios"

export default function AppTracking() {
  const [activeTab1, setactiveTab1] = useState("5")

  const [modal_toggle, setmodal_toggle] = useState(false)
  const [comparisonList, setComparisonList] = useState([])
  const [comparisonBetween, setComparisonBetween] = useState([])
  const storedComparisonApps = sessionStorage.getItem("comparisonBetween")
  const storedCamparisonList = sessionStorage.getItem("comparisonList")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchTrackedAppList()
    console.log(localStorage.getItem("authToken"))
    // console.log(`List on load: ${comparisonBetween}`)
    // setComparisonBetween(comparisonList)
  }, [])

  const fetchTrackedAppList = () => {
    if (storedComparisonApps) {
      setComparisonList(JSON.parse(storedComparisonApps))
      setComparisonBetween(JSON.parse(storedComparisonApps))
    } else {
      setLoading(true)
      callAppTrackapi()
    }
  }

  const callAppTrackapi = async () => {
    await axios
      .get(`${api}/tracker/tracking/app`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then(res => {
        //console.log(res.data.games)
        setComparisonList(res.data.games)
        //adding data in session item
        sessionStorage.setItem(
          "comparisonBetween",
          JSON.stringify(res.data.games)
        )
      })
    setLoading(false)
  }

  function toggle_modal() {
    setmodal_toggle(!modal_toggle)
  }
  const handleselectedapp = async app => {
    setLoading(true)
    // Add the selected app to the array
    const appExists = comparisonList.some(
      item => item.game_details.item_id === app.item_id
    )
    // console.log("existance checked")
    // console.log(app)
    if (!appExists) {
      // Add the selected app to the array
      const gameid = { item_id: app.item_id }
      console.log(gameid)
      try {
        const res = await axios.post(`${api}/tracker/tracking/app/`, gameid, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        //console.log(res)
      } catch (error) {
        console.error("Error sending data:", error)
      }

      //console.log(comparisonList)
    } else {
      console.log("App is already in the comparison list.Removing it")
      removeApp(app.item_id)
    }
    callAppTrackapi()
    toggle_modal()
  }

  async function removeApp(val) {
    setLoading(true)
    const gameid = { item_id: val }
    try {
      const res = await axios.delete(`${api}/tracker/tracking/app.remove`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: { item_id: val }, // Adjust this key if the payload requires a different name
      })
      console.log(res)
      await callAppTrackapi()
    } catch (error) {
      console.error("Error sending data:", error)
    } finally {
      setLoading(false)
    }
  }

  function toggle1(tab) {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content m-3">
        <Breadcrumb title="App Tracking" breadcrumbItem="App Tracking" />
        <Row className="m-3">
          <Card className="">
            <CardBody>
              <Row className="mt-3">
                <div className="d-flex justify-content-between">
                  <p>Compare 2-6 Apps</p>
                  {/* <Button onClick={() => setComparisonList([])}>
                    Clear Selection
                  </Button> */}
                </div>
              </Row>
              <Row>
                <div className="d-flex flex-wrap gap-2 justify-content-center">
                  {comparisonList.map((app, index) => {
                    return (
                      <div className="col-md-2">
                        <Card
                          className="app-card-container2 border border-1 rounded"
                          style={{
                            border: "none",
                            // Makes the card look clickable
                            transition: "background-color 0.3s ease", // Smooth transition for hover effect
                            minHeight: 80,
                          }}
                        >
                          <Link
                            to={`/gamedashboard/${app.game_details.item_id}`}
                            className=""
                            state={{
                              app_id: app.game_details.item_id,
                              data: app.game_details,
                              selected_tab: "track",
                            }}
                            style={{ zIndex: 2 }}
                          >
                            <>
                              <CardImg
                                className="p-2"
                                src={
                                  app.game_details?.image_url ||
                                  "https://via.placeholder.com/50"
                                }
                                alt="image cap"
                                style={{
                                  minHeight: 100,
                                  maxHeight: 100,
                                  // minWidth: 180,
                                  // maxWidth: 180,
                                  objectfit: "cover",
                                }}
                              />
                              <CardBody className="mx-auto">
                                <div
                                  className="d-flex gap-2 my-auto"
                                  style={{ minHeight: 80, minWidth: 90 }}
                                >
                                  <p className="my-auto">
                                    <b>{app.game_details.name}</b>
                                  </p>
                                </div>
                              </CardBody>
                            </>
                          </Link>
                          <Button
                            className="mb-2"
                            color="danger"
                            style={{
                              width: "90%",
                              cursor: "pointer",
                              alignSelf: "center",
                              zIndex: 2,
                            }}
                            value={app.game_details.item_id}
                            onClick={() => removeApp(app.game_details.item_id)} // Replace with your click handler
                          >
                            Remove
                          </Button>

                          <div className="hover-overlay">
                            <span className="remove-text">REMOVE</span>
                          </div>
                        </Card>

                        {/* Hover Overlay */}
                      </div>
                    )
                  })}

                  <div className="col-md-2">
                    {/* Max select limit for apps */}
                    {comparisonList.length < 5 ? (
                      <Card
                        className="app-card-container2  border border-1 rounded"
                        onClick={toggle_modal} // Replace with your click handler
                        style={{
                          border: "none",
                          cursor: "pointer", // Makes the card look clickable
                          transition: "background-color 0.3s ease", // Smooth transition for hover effect
                          minHeight: 80,
                        }}
                      >
                        <CardBody className=" mx-auto">
                          <div
                            className="d-flex gap-2 my-auto mb-2"
                            style={{ minHeight: 180 }}
                          >
                            <FaCirclePlus size={20} className="my-auto" />
                            <p className="my-auto">
                              <b>Add game</b>
                            </p>
                          </div>
                        </CardBody>
                      </Card>
                    ) : (
                      <>
                        <Card
                          className="app-card-container2 border border-1 rounded"
                          style={{
                            border: "none",
                            transition: "background-color 0.3s ease", // Smooth transition for hover effect
                            minHeight: 80,
                            cursor: "not-allowed",
                          }}
                        >
                          <CardBody className=" mx-auto">
                            {/* <FaCirclePlus size={20} className="my-auto" /> */}

                            <div
                              className="d-flex justify-content-center gap-2 my-auto mb-2"
                              style={{ minHeight: 180 }}
                            >
                              <p
                                className="my-auto"
                                style={{ textAlign: "center" }}
                              >
                                <b>Subscribe to Add more</b>
                              </p>
                            </div>
                          </CardBody>
                        </Card>
                      </>
                    )}
                  </div>
                </div>
              </Row>

              <Row style={{ paddingBottom: 20 }}>
                <div className="d-flex justify-content-center">
                  <Button
                    className="col-md-3"
                    color={comparisonList.length < 1 ? "secondary" : "primary"}
                    onClick={() => setComparisonBetween(comparisonList)}
                    style={{
                      cursor:
                        comparisonList.length < 1 ? "not-allowed" : "pointer", // Change cursor based on selection
                      // Different color for already selected apps
                    }}
                  >
                    Compare
                  </Button>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Row>

        {/* Comparison tables and charts */}
        <Row className="m-3">
          <ComparisonTabs appListData={comparisonBetween}></ComparisonTabs>
        </Row>
      </div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <Spinner size="lg" color="primary" />
        </div>
      )}
      <AppListModal
        modaltoggle={modal_toggle}
        togglemodal={toggle_modal}
        onAppSelect={handleselectedapp}
        alreadySelectedApps={comparisonList}
      ></AppListModal>
    </React.Fragment>
  )
}
