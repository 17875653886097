import React, { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Input,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import FeaturesTable from "../../marketResearch/appTracking/FeaturesTable"
import RankedDashedLine from "../charts-graphs/RankDashedLine"
import ScreenShotCarousel from "./ScreenShotCarousel"
import Reviews from "./Reviews"
import { api } from "../../../utils/variables"
import axios from "axios"
import { FaInfoCircle } from "react-icons/fa"
import TrackingDetailsPopup from "../Modals/TrackingDetails"
import Apaexlinecolumn from "../charts-graphs/ApexLineChart"

export default function InfoTabs({ appListData, trackedApp, selectedTab }) {
  const [activeTab1, setactiveTab1] = useState("1")
  const [showTrackData, setshowTrackData] = useState(true)
  const storedComparisonApps = JSON.parse(
    sessionStorage.getItem("comparisonBetween")
  )
  const itemIds = trackedApp.map(app => app.game_details?.item_id)

  const [selectedMetric, setSelectedMetric] = useState(null) // Track the selected metric
  const [metricValue, setMetricValue] = useState(null)

  const [modal_toggle, setmodal_toggle] = useState(false)

  const ktrCardRef = useRef(null) // Reference for the KTR card
  const kuRef = useRef(null) //reference for KU card
  const asrCardRef = useRef(null) //reference for asr card

  const scrollToKTR = () => {
    console.log(ktrCardRef.current)
    if (ktrCardRef.current) {
      ktrCardRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }
  const scrollToKU = () => {
    console.log(kuRef.current)
    if (kuRef.current) {
      kuRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  const scrollToASR = () => {
    console.log(asrCardRef.current)
    if (asrCardRef.current) {
      asrCardRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }
  const toggle_modal = (metric = null, value) => {
    setSelectedMetric(metric) // Set the selected metric
    setmodal_toggle(prev => !prev) // Toggle modal visibility
    setMetricValue(value)
  }

  useEffect(() => {
    // console.log(itemIds)
    // console.log(storedComparisonApps)
    // console.log(selectedTab)

    //check if the page is redirected from tracking page
    if (selectedTab == "track") {
      setactiveTab1("4")
    }
    // console.log(`List on load: ${comparisonBetween}`)
    // setComparisonBetween(comparisonList)
  }, [])

  const formatDateToYYYYMMDD = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0") // Add 1 to month (0-indexed) and pad with leading zero
    const day = String(date.getDate()).padStart(2, "0") // Pad day with leading zero if necessary
    return `${year}-${month}-${day}`
  }

  const onemonthbackDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1 - 1).padStart(2, "0") // Add 1 to month (0-indexed) and pad with leading zero
    const day = String(date.getDate()).padStart(2, "0") // Pad day with leading zero if necessary
    return `${year}-${month}-${day}`
  }

  const addApp = async value => {
    const gameid = { item_id: { value } }
    try {
      const res = await axios.post(`${api}/tracker/tracking/app/`, gameid, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      //console.log(res)
    } catch (error) {
      console.error("Error sending data:", error)
    }
  }

  async function removeApp(val) {
    // setLoading(true)
    const gameid = { item_id: val }
    try {
      const res = await axios.delete(`${api}/tracker/tracking/app.remove`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: { item_id: val }, // Adjust this key if the payload requires a different value
      })
      console.log(res)
      await callAppTrackapi()
      setshowTrackData(false)
    } catch (error) {
      console.error("Error sending data:", error)
    }
    // finally {
    //   setLoading(false)
    // }
  }
  const callAppTrackapi = async () => {
    await axios
      .get(`${api}/tracker/tracking/app`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then(res => {
        console.log(res.data.games)
        //adding data in session item
        sessionStorage.setItem(
          "comparisonBetween",
          JSON.stringify(res.data.games)
        )
      })
  }

  // const fetchAppDetails = app_id => {
  //   //https://api.triviumpro.in/predefined/keywords
  //   axios.get(`${api}/tracker/app/${app_id}`, {}).then(res => {
  //     setAppdetail(res.data.details)
  //     //console.log(res.data.details)
  //   })
  // }
  // const handleselectedapp = async app => {
  //   setLoading(true)
  //   // Add the selected app to the array
  //   const appExists = comparisonList.some(
  //     item => item.game_details.item_id === app.item_id
  //   )
  //   // console.log("existance checked")
  //   // console.log(app)
  //   if (!appExists) {
  //     // Add the selected app to the array
  //     const gameid = { item_id: app.item_id }
  //     console.log(gameid)
  //     try {
  //       const res = await axios.post(`${api}/tracker/tracking/app/`, gameid, {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  //         },
  //       })
  //       //console.log(res)
  //     } catch (error) {
  //       console.error("Error sending data:", error)
  //     }

  //     //console.log(comparisonList)
  //   } else {
  //     console.log("App is already in the comparison list.Removing it")
  //     // removeApp(app.item_id)
  //   }
  //   callAppTrackapi()
  //   // toggle_modal()
  // }

  const [startDate, setStartDate] = useState(onemonthbackDate(new Date()))
  const [endDate, setEndDate] = useState(formatDateToYYYYMMDD(new Date()))

  const [ktrStartDate, setKtrStartDate] = useState(onemonthbackDate(new Date()))
  const [ktrEndDate, setKtrEndDate] = useState(formatDateToYYYYMMDD(new Date()))
  const [asrStartDate, setAsrStartDate] = useState(onemonthbackDate(new Date()))
  const [asrEndDate, setAsrEndDate] = useState(formatDateToYYYYMMDD(new Date()))
  const [formstartDate, setformStartDate] = useState()

  function toggle1(tab) {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  return (
    <>
      <Nav pills className="nav-justified bg-light">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "1",
            })}
            onClick={() => {
              toggle1("1")
            }}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-home"></i>
            </span>
            <span className="d-none d-sm-block">About</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "2",
            })}
            onClick={() => {
              toggle1("2")
            }}
          >
            <span className="d-block d-sm-none">
              <i className="far fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Category Ranking</span>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "3",
            })}
            onClick={() => {
              toggle1("3")
            }}
          >
            <span className="d-block d-sm-none">
              <i className="far fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Ratings and Reviews</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "4",
            })}
            onClick={() => {
              toggle1("4")
            }}
          >
            <span className="d-block d-sm-none">
              <i className="far fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Tracking</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab1} className="p-3 text-muted">
        <TabPane tabId="1">
          <div className="d-flex flex-wrap justify-content-between gap-1 mt-3">
            <Card
              outline
              color="white"
              className="border border-white"
              style={{ width: "550px" }}
            >
              <CardHeader className="bg-transparent border-white text-white">
                Release Detail
              </CardHeader>
              <CardBody className="p-4">
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <tbody>
                      <tr>
                        <td>Current Version :</td>
                        <td className="text-end">
                          {appListData?.version || "NA"}
                        </td>
                      </tr>
                      <tr>
                        <td>Age Rating : </td>
                        <td className="text-end">
                          {appListData?.age_rating || "NA"}
                        </td>
                      </tr>
                      <tr>
                        <td>Publisher:</td>
                        <td className="text-end">
                          {appListData?.publisher || "NA"}
                        </td>
                      </tr>
                      <tr>
                        <td>Release Date : </td>
                        <td className="text-end">
                          {appListData?.created_at || "NA"}
                        </td>
                      </tr>
                      <tr className="bg-light">
                        <th>File Size :</th>
                        <td className="text-end">
                          <span className="fw-bold">
                            {appListData?.size / 1000000 -
                              (appListData?.size % 1000000) / 1000000}{" "}
                            MB
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>

            <Card
              outline
              color="white"
              className="border border-white"
              style={{ width: "550px" }}
            >
              <CardHeader className="bg-transparent border-white text-white">
                Description
              </CardHeader>
              <CardBody>
                <CardText>{appListData?.description || "NA"}</CardText>
              </CardBody>
            </Card>
          </div>
          <Card style={{ width: "100%" }}>
            <CardHeader>Screenshots</CardHeader>
            <CardBody>
              <ScreenShotCarousel appData={appListData}></ScreenShotCarousel>
            </CardBody>
          </Card>
        </TabPane>
        <TabPane tabId="2">
          <div className="mt-3">
            <p>
              <b>Rank Development</b>
            </p>
            <div className="mt-5 d-flex flex-wrap justify-content-between">
              <div className="d-flex flex-wrap gap-4">
                <label htmlFor="example-date-input" className="col-form-label">
                  From
                </label>
                <div className="">
                  <input
                    className="form-control"
                    type="date"
                    defaultValue={startDate}
                    onChange={e => setStartDate(e.target.value)}
                    id="example-date-input"
                  />
                </div>
              </div>
              <div className="d-flex flex-wrap gap-4">
                <label htmlFor="example-date-input" className="col-form-label">
                  To
                </label>
                <div className="">
                  <input
                    className="form-control"
                    type="date"
                    defaultValue={endDate}
                    onChange={e => setEndDate(e.target.value)}
                    id="example-date-input"
                  />
                </div>
              </div>
            </div>
            <hr></hr>
            <div>
              <RankedDashedLine startDate={startDate} endDate={endDate} />
            </div>
          </div>
        </TabPane>
        <TabPane tabId="3">
          <Reviews appdata={appListData}></Reviews>
        </TabPane>
        <TabPane tabId="4">
          {/* Check if game_id is in itemIds */}
          <div className="mt-3">
            {itemIds.includes(appListData.item_id) ? (
              storedComparisonApps
                .filter(app => app.game_details.item_id === appListData.item_id)
                .map((app, index) => (
                  <div key={index}>
                    <Button
                      onClick={() => {
                        removeApp(appListData.item_id)
                      }}
                    >
                      Untrack App
                    </Button>
                    <Row className="mt-3">
                      <div>
                        <Card
                          outline
                          color="white"
                          className="border border-white"
                          style={{ width: "full" }}
                        >
                          <CardHeader className="bg-transparent border-white text-white">
                            Game Analytics
                          </CardHeader>
                          <CardBody className="p-4">
                            <div className="table-responsive">
                              <Table className="table mb-0">
                                <tbody>
                                  <tr>
                                    <td>
                                      <p>
                                        ASR :
                                        <FaInfoCircle onClick={scrollToASR} />
                                      </p>
                                    </td>
                                    <td className="text-end">
                                      {Math.floor(
                                        app.game_details.analytics?.asr * 100
                                      ) / 100 || "NA"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      KTR :
                                      <FaInfoCircle onClick={scrollToKTR} />
                                    </td>
                                    <td className="text-end">
                                      {Math.floor(
                                        app.game_details.analytics?.ktr * 100
                                      ) / 100 || "NA"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      KU:
                                      <FaInfoCircle onClick={scrollToKU} />{" "}
                                    </td>
                                    <td className="text-end">
                                      {Math.floor(
                                        app.game_details.analytics?.ku * 100
                                      ) / 100 || "NA"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      AUR:
                                      {/* <FaInfoCircle /> */}
                                    </td>
                                    <td className="text-end">
                                      {Math.floor(
                                        app.game_details.analytics?.aur * 100
                                      ) / 100 || "NA"}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </Row>
                    <Row className="mt-4 ktrCard">
                      <div ref={ktrCardRef}>
                        <Card className="">
                          <CardHeader className="bg-primary text-white">
                            KTR
                          </CardHeader>
                          <CardBody className="w-100 ">
                            <div>
                              <p>
                                {" "}
                                Keyword Relevance measures how closely the app’s
                                title, description, and metadata match the
                                keywords users are searching for
                              </p>
                            </div>
                            <div className="mt-3">
                              <div className="mt-5 d-flex flex-wrap justify-content-between">
                                <div className="d-flex flex-wrap gap-4">
                                  <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                  >
                                    From
                                  </label>
                                  <div className="">
                                    <input
                                      className="form-control"
                                      type="date"
                                      defaultValue={ktrStartDate}
                                      onChange={e =>
                                        setKtrStartDate(e.target.value)
                                      }
                                      id="example-date-input"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap gap-4">
                                  <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                  >
                                    To
                                  </label>
                                  <div className="">
                                    <input
                                      className="form-control"
                                      type="date"
                                      defaultValue={ktrEndDate}
                                      onChange={e =>
                                        setKtrEndDate(e.target.value)
                                      }
                                      id="example-date-input"
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr></hr>
                              <div>
                                <RankedDashedLine
                                  startDate={ktrStartDate}
                                  endDate={ktrEndDate}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </Row>
                    <Row className="mt-4 kuCard">
                      <div ref={kuRef}>
                        <Card>
                          <CardHeader className="bg-primary text-white">
                            KU
                          </CardHeader>
                          <CardBody>
                            <div>
                              <p>
                                Keyword Usage measures how well an app utilizes
                                high-ranking or relevant keywords in its
                                metadata (title, description, and tags).
                              </p>
                            </div>
                            <div className="pt-3">
                              <Apaexlinecolumn></Apaexlinecolumn>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </Row>
                    <Row className="mt-4 asrCard">
                      <div ref={asrCardRef}>
                        <Card className="">
                          <CardHeader className="bg-primary text-white">
                            ASR
                          </CardHeader>
                          <CardBody className="w-100 ">
                            <div>
                              <p>
                                {" "}
                                Storage Ranking is based on many aspects like
                                KU,Downloads,KTR etc
                              </p>
                            </div>
                            <div className="mt-3">
                              <div className="mt-5 d-flex flex-wrap justify-content-between">
                                <div className="d-flex flex-wrap gap-4">
                                  <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                  >
                                    From
                                  </label>
                                  <div className="">
                                    <input
                                      className="form-control"
                                      type="date"
                                      defaultValue={asrStartDate}
                                      onChange={e =>
                                        setAsrStartDate(e.target.value)
                                      }
                                      id="example-date-input"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap gap-4">
                                  <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                  >
                                    To
                                  </label>
                                  <div className="">
                                    <input
                                      className="form-control"
                                      type="date"
                                      defaultValue={asrEndDate}
                                      onChange={e =>
                                        setAsrEndDate(e.target.value)
                                      }
                                      id="example-date-input"
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr></hr>
                              <div>
                                <RankedDashedLine
                                  startDate={asrStartDate}
                                  endDate={asrEndDate}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </Row>
                  </div>
                ))
            ) : (
              <Button
                color="primary"
                onClick={() => {
                  addApp()
                  // handleselectedapp(appListData.item_id)
                }}
              >
                Track App
              </Button>
            )}
          </div>
        </TabPane>
      </TabContent>
      <TrackingDetailsPopup
        modaltoggle={modal_toggle}
        togglemodal={toggle_modal}
        metric={selectedMetric}
        value={metricValue}
      />
    </>
  )
}
