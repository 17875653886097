import { useEffect, useState } from "react"
import { Button, Table } from "reactstrap"
import SubscriptionPopUpModal from "../../common/Modals/SubscriptionPopModal"

export default function FeaturesTable({ appListData }) {
  const appData = appListData.game_details
  useEffect(() => {
    console.log(appListData)
    console.log(appData)
  }, [])

  const [modal_toggle, setmodal_toggle] = useState(false)
  function toggle_modal() {
    setmodal_toggle(!modal_toggle)
  }
  return (
    <div className="table-responsive">
      <Table className="table table-dark mb-0 ">
        <thead>
          <tr>
            <th className="col-md-4" style={{ opacity: "70%" }}>
              Feature
            </th>
            {appListData.map((app, index) => {
              return <th className="text-center">{app.game_details.name}</th>
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="sidecol" style={{ opacity: "70%" }}>
              Category
            </td>
            {appListData.map((app, index) => {
              return (
                <td className="text-center">
                  {app.game_details?.category || "NA"}
                </td>
              )
            })}
          </tr>
          <tr>
            <td className="sidecol" style={{ opacity: "70%" }}>
              Age Rating
            </td>
            {appListData.map((app, index) => {
              return (
                <td className="text-center">
                  {app.game_details?.age_rating || "NA"}
                </td>
              )
            })}
          </tr>
          <tr>
            <td className="sidecol" style={{ opacity: "70%" }}>
              Game Modes
            </td>
            {appListData.map((app, index) => {
              return (
                <td className="text-center">
                  {app.game_details?.game_modes?.join(", ") || "NA"}
                </td>
              )
            })}
          </tr>
          <tr>
            <td className="sidecol" style={{ opacity: "70%" }}>
              Genres
            </td>
            {appListData.map((app, index) => {
              return (
                <td className="text-center">
                  {app.game_details?.genres?.join(", ") || "NA"}
                </td>
              )
            })}
          </tr>
          <tr>
            <td className="sidecol" style={{ opacity: "70%" }}>
              Price
            </td>
            {appListData.map((app, index) => {
              return (
                <td className="text-center">
                  {app?.game_details?.price?.amount +
                    " " +
                    app?.game_details?.price?.currency || "NA" + " "}
                </td>
              )
            })}
          </tr>

          <tr>
            <td className="sidecol" style={{ opacity: "70%" }}>
              Ratings
            </td>
            {appListData.map((app, index) => {
              const ratingData = app.game_details?.rating
              const istotalratingAvailable = ratingData?.total_rating || false
              var overallRating = 0.0
              if (istotalratingAvailable) {
                overallRating = (
                  (ratingData.rating_index["1"] +
                    ratingData.rating_index["2"] * 2 +
                    ratingData.rating_index["3"] * 3 +
                    ratingData.rating_index["4"] * 4 +
                    ratingData.rating_index["5"] * 5) /
                  ratingData.total_rating
                ).toFixed(1)
              }
              return <td className="text-center">{overallRating}</td>
            })}
          </tr>
          <tr className="">
            <td className="sidecol" style={{ opacity: "70%" }}>
              Total Reviews
            </td>
            {appListData.map((app, index) => {
              return (
                <td className="text-center">
                  {app.game_details.rating?.total_rating || "NA"}
                </td>
              )
            })}
          </tr>
          <tr className="">
            <td className="sidecol" style={{ opacity: "70%" }}>
              Supported Platforms
            </td>

            {appListData.map((app, index) => {
              return (
                <td className="text-center">
                  {app.game_details?.supported_platforms?.join(", ") || "NA"}
                </td>
              )
            })}
          </tr>
          <tr className="">
            <td className="sidecol blurdiv" style={{ opacity: "70%" }}>
              Supported Platforms
            </td>

            {appListData.map((app, index) => {
              return (
                <td className="text-center blurdiv">
                  {app.game_details?.supported_platforms?.join(", ") || "NA"}
                </td>
              )
            })}
          </tr>
        </tbody>
      </Table>
      <div className="d-flex justify-content-center" style={{ width: "100%" }}>
        <Button className="bg-gradient-light text-black" onClick={toggle_modal}>
          Unlock More with Preimum
        </Button>
      </div>
      <SubscriptionPopUpModal
        modaltoggle={modal_toggle}
        togglemodal={toggle_modal}
      />
    </div>
  )
}
