import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
// Function to convert "dd-mm-yy" to a standard Date object
const convertToDate = dateStr => {
  const [year, month, day] = dateStr.split("-")
  return new Date(`${year}`, month - 1, day) // Adjusting for year and month being 0-indexed
}

// Function to generate dates between start and end date
const generateDateRange = (start, end) => {
  const startDate = convertToDate(start)
  const endDate = convertToDate(end)
  const dates = []

  for (
    let date = startDate;
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    const formattedDate = `${String(date.getDate()).padStart(
      2,
      "0"
    )}-${date.toLocaleString("default", {
      month: "short",
    })}-${date.getFullYear()}`
    dates.push(formattedDate)
  }

  return dates
}
const adjustDataToDateRange = (data, dateRangeLength) => {
  if (data.length > dateRangeLength) {
    // Truncate data to match the length of the date range
    return data.slice(0, dateRangeLength)
  } else {
    // Extend data with null or placeholders to match the length of the date range
    return [...data, ...Array(dateRangeLength - data.length).fill(null)]
  }
}

const RankedDashedLine = ({ startDate, endDate }) => {
  const [xAxisCategories, setXAxisCategories] = useState([])
  const [topGrossingData, setTopGrossingData] = useState([])
  const [freeRankData, setFreeRankData] = useState([])
  const baseTopGross = [
    45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10, 26, 21, 20, 6, 8, 45, 52, 38,
    24, 33, 26, 21, 20, 6, 8, 15, 10, 26, 21, 20, 6, 8,
  ]
  const baseFreeRank = [
    36, 42, 60, 42, 13, 18, 29, 37, 36, 51, 32, 35, 26, 23, 22, 8, 9, 36, 42,
    60, 42, 13, 18, 29, 37, 36, 51, 32, 35, 26, 23, 22, 8, 9,
  ]

  const series = [
    {
      name: "Top Grossing",
      // data: [
      //   45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10, 26, 21, 20, 6, 8, 45, 52,
      //   38, 24, 33, 26, 21, 20, 6, 8, 15, 10, 26, 21, 20, 6, 8,
      // ],
      data: topGrossingData,
    },
    {
      name: "Free Rank",
      // data: [
      //   36, 42, 60, 42, 13, 18, 29, 37, 36, 51, 32, 35, 26, 23, 22, 8, 9, 36,
      //   42, 60, 42, 13, 18, 29, 37, 36, 51, 32, 35, 26, 23, 22, 8, 9,
      // ],
      data: freeRankData,
    },
    // {
    //   name: "Total Visits",
    //   data: [89, 56, 74, 98, 72, 38, 64, 46, 84, 58, 46, 49, 26, 21, 20, 6, 8],
    // },
  ]
  const options = {
    chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
    colors: ["#5b73e8", "#34c38f"],
    //"#f1b44c"
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 4, 3],
      curve: "straight",
      dashArray: [0, 8, 5],
    },
    title: {},
    markers: {
      size: 0,

      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      // categories: [
      //   "01 Jan",
      //   "02 Jan",
      //   "03 Jan",
      //   "04 Jan",
      //   "05 Jan",
      //   "06 Jan",
      //   "07 Jan",
      //   "08 Jan",
      //   "09 Jan",
      //   "10 Jan",
      //   "11 Jan",
      //   "12 Jan",
      //   "13 Jan",
      //   "14 Jan",
      //   "15 Jan",
      //   "16 Jan",
      // ],
      categories: xAxisCategories,
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return val + " (mins)"
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val + " per session"
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  }
  // Update x-axis whenever startDate or endDate changes
  useEffect(() => {
    const newCategories = generateDateRange(startDate, endDate)
    setXAxisCategories(newCategories)

    // Adjust the series data based on the new date range length
    setTopGrossingData(
      adjustDataToDateRange(baseTopGross, newCategories.length)
    )
    setFreeRankData(adjustDataToDateRange(baseFreeRank, newCategories.length))
  }, [startDate, endDate])

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="380"
      className="apex-charts"
    />
  )
}

export default RankedDashedLine
