import React from "react"
import { MDBDataTable } from "mdbreact"
import LineChart from "../common/charts-graphs/RankDashedLine"
import BarChart from "../common/charts-graphs/BarChart"
import { Button, Input } from "reactstrap"
import AppCard from "./AppCard"
// import "mdbreact/dist/css/mdb.css"
// import "bootstrap/dist/css/bootstrap.min.css"
import "../../assets/scss/datatables.scss"
const PerformanceDataTable = ({ inputdata, gamesData }) => {
  const data = {
    columns: [
      {
        label: "Sno",
        field: "sno",
        sort: "asc",
      },
      {
        label: "App",
        field: "app",
        sort: "asc",
      },
      {
        label: "Keyword Usage",
        field: "keywordUsage",
        sort: "asc",
      },
      {
        label: "Estimated Downloads",
        field: "downloads",
        sort: "asc",
      },
      {
        label: "Store Rank",
        field: "storeRank",
        sort: "asc",
        width: 150,
      },
      {
        label: "New Ratings",
        field: "newratings",
        sort: "asc",
      },
      {
        label: "Updated",
        field: "updated",
        sort: "asc",
      },
      {
        label: "Released",
        field: "released",
        sort: "asc",
      },

      // Add more columns here
    ],
    rows: gamesData.map((game, index) => ({
      sno: index + 1,
      app: <AppCard key={game.item_id} gamedata={game} />,
      appNameSearch: game.name, //hidden field holds appname
      keywordUsage: (
        <div className="d-flex flex-wrap justify-content-between gap-0">
          <p>{inputdata}</p>
        </div>
      ),
      downloads: (
        <div style={{ minWidth: "150px", minHeight: "100%" }}>
          {/* <LineChart downloads={game?.downloads || "N/A"} /> */}
          <p>{game?.downloads || "N/A"}</p>
        </div>
      ),

      storeRank: "NA", //game.rating?.total_rating || "N/A",

      newratings: (
        <div style={{ minWidth: "150px" }}>
          <BarChart ratingData={game.rating} />
        </div>
      ),
      updated: "N/A",
      released: game.created_at,
    })),
  }

  return <MDBDataTable responsive bordered data={data} />
}

export default PerformanceDataTable
