import React from "react"
import { MDBDataTable } from "mdbreact"
// import "mdbreact/dist/css/mdb.css"
// import "bootstrap/dist/css/bootstrap.min.css"
import "../../../assets/scss/datatables.scss"
// import SmallAppCard from "./categoryAnalysis/SmallAppCard"
// import StarRatings from "./categoryAnalysis/StartRatings"
const CategoryTrackingDataTable = ({ inputdata, categoryData }) => {
  const data = {
    columns: [
      {
        label: "Category",
        field: "Category",
        sort: "asc",
      },
      {
        label: "Position",
        field: "Position",
        sort: "asc",
      },
      {
        label: "Change",
        field: "Change",
        sort: "asc",
      },
      {
        label: "Popularity",
        field: "Popularity",
        sort: "asc",
      },
      {
        label: "Competitiveness",
        field: "Competitiveness",
        sort: "asc",
      },

      //   {
      //     label: "Released",
      //     field: "released",
      //     sort: "asc",
      //   },

      // Add more columns here
    ],
    rows: categoryData?.map((word, index) => ({
      Category: word,
      //   app: <SmallAppCard key={game._id} gamedata={game} />,
      Position: "-",
      Change: "NA", //hidden field holds appname
      Popularity: "-",
      Competitiveness: word?.downloads || "NA",
    })),
  }

  return <MDBDataTable responsive bordered data={data} />
}

export default CategoryTrackingDataTable
